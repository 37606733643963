

<template>

    <div >


        <table width="100%" style="border: 1px !important;">
            <tr v-for="(item, index) in data" v-bind:key="index" class="oeno_wine_name">
                <td>
                        <span v-if="data.length > 1">{{ item.quantity }} x    </span><i class="fa fa-check w-full fill-current white">GBP {{ getPrice(item.sell_price) }}</i><br/>
                </td>
            </tr>
        </table>
        
    </div>


</template>
   
<script>


export default {
    name: 'PriceRange',
    data() {
        return {
            priceData: [],
        }
    },
    props: {
        data: {
            type: Array,
            default: () => ({})
        },
        source: {
            type: String,
            default: () => ({})
        },
    },
    mounted() {
    },
    methods: {
        getItemsStyle(index, price) {

            console.log(index, price)

            return "float:left;width:10px;height:10px;background:black;border-radius:50%;position:relative;top:-4px;margin-right:100px;"

        },
        getPrice(price) {

            var adminCharge = parseFloat(price) * parseFloat(process.env.VUE_APP_BUYER_CHARGE_PERC)
            return this.currencyFormatter(parseFloat(price) + adminCharge)

        }
    },
    computed: {

    }

}
</script>

