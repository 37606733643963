<template>
  <div>
    <div
      style="border-bottom: 0.5px solid #000000"
      class="grid grid-cols-3 pb-2"
    >
      <p class="oeno_col_heading ml-10">Name</p>

      <p class="float-right oeno_col_heading">Quantity</p>

      <p class="float-right oeno_col_heading">Price</p>
    </div>

    <div
      style="border-bottom: 0.5px solid #000000"
      class="grid grid-cols-3 pb-10"
    >
      <div class="ml-10 mt-5">
        <span class="oeno_wine_name"> {{ item.name }}</span
        ><br />
        <span class="text-xl font-medium font-Montserrat">{{
          winary(item)
        }}</span
        ><br />
      </div>
      <div class="oeno_wine_name mt-5">
        <span v-if="cases > 0"
          >{{ cases }} Case<span v-if="cases > 1">s</span></span
        >
        <span v-if="bottles > 0"
          >{{ bottles }} Bottle<span v-if="bottles > 1">s</span></span
        >
      </div>

      <div class="mt-5" v-if="overrideprice == ''">
        <price-range :data="priceQuantities" :source="item.sell_size" />
      </div>

      <div class="oeno_wine_name mt-5" v-if="overrideprice != ''">
        {{overrideprice}}
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import PriceRange from "@/components/wine/PriceRangeSellSimple.vue";

export default {
  name: "BuyWineSummary",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    cases: {
      type: Number,
      default() {
        return 0;
      },
    },
    bottles: {
      type: Number,
      default() {
        return 0;
      },
    },
    priceQuantities: {
      type: Array,
      default() {
        return [];
      },
    },
    overrideprice: {
      type: String,
      default() {
        return "";
      },
    },
    
  },
  components: {
    PriceRange,
  },
  methods: {
    wineImage(wine) {
      var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;

      if (wine.image != "") {
        wineimageurl = process.env.VUE_APP_WINE_IMAGE_PATH + wine.image;
      }

      return wineimageurl;
    },
    getQuantity(item) {
      if (item.wines.units_per_case > 1) {
        return (
          item.quantity * item.wines.units_per_case +
          " (" +
          item.quantity +
          " cases)"
        );
      } else {
        return item.quantity * item.wines.units_per_case;
      }
    },
    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    winary(wine) {
      var winery = "";
      winery = wine.winery + " " + wine.vintage;

      if (wine.size != "") {
        winery = winery + " " + wine.size + "ML";
      }

      return winery;
    },

    getCurrency(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.currency;
    },

    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }

      var total =
        parseInt(
          parseFloat(
            wine.quantity *
              wine.wines.units_per_case *
              wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

      var buytotal =
        wine.quantity * wine.wines.units_per_case * wine.bottle_price;

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc =
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) *
        100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
      };
    },

    getPurchasePrice(item) {
      return item.bottle_price * item.quantity * item.wines.units_per_case;
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);
      this.getTotalPrice = priceValues.total;
      return this.getTotalPrice;
    },

    getChange(investment, wine, showperc = false) {
      var priceValues = this.getValuesForItem(investment, wine);
      if (showperc) {
        return (
          this.currencyFormatter(priceValues.changePrice) +
          " ( " +
          this.currencyFormatter(priceValues.changePerc) +
          " %)"
        );
      } else {
        return this.currencyFormatter(priceValues.changePrice);
      }
    },

    itemProfit(item) {
      return item.livePrices.data["price-average"] - item.bottle_price;
    },
  },
};
</script>
