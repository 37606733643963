<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Add to your Portfolio"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="false"
          :showPie="false"
          :showWine="true"
          :wine="stock"
        />
      </div>

      <div class="col-span-8">
        <span id="download_loader" v-if="!showdata"></span>
        <div v-if="showdata">
          <wine-detail
            :item="stock"
            :cases="stock.stocks.length"
            :priceQuantities="priceQuantities"
          />
          <div class="grid grid-cols-2 gap-4 mt-5 pb-5 m-10">
            <div class="pr-20 mt-10">
              <span v-html="stock.description"></span>
            </div>

            <div>
              <div class="grid grid-cols-5 gap-4">
                <div class="col-span-3 mt-6">
                  <span class="oeno_portfolio_winetitle"
                    >Please enter the number of {{ stock.sell_size }}s you would
                    like to buy</span
                  >
                </div>
                <div class="col-span-2">
                  <md-field>
                    <md-input
                      v-model="quantity"
                      @keyup="validateCases(maxcases)"
                      @change="validateCases(maxcases)"
                      type="number"
                    ></md-input>
                  </md-field>
                  <span v-if="!validCases" class="errortext"
                    >The amount of {{ stock.sell_size }}s to be bought needs to
                    greater than 1</span
                  >
                </div>

                <div class="col-span-3 mt-6">
                  <span class="oeno_portfolio_winetitle">Sub Total</span>
                </div>
                <div class="col-span-2">
                  <span class="float-right oeno_portfolio_price"
                    >GBP&nbsp;{{
                      currencyFormatter(calcSubTotal() + calcCharge())
                    }}</span
                  >
                </div>

                <div class="col-span-3 mt-6">
                  <span class="oeno_portfolio_winetitle">Total</span>
                </div>
                <div class="col-span-2">
                  <span class="float-right oeno_portfolio_price"
                    >GBP&nbsp;{{ currencyFormatter(calcTotal()) }}</span
                  >
                </div>

                <div class="col-span-5">
                  <button
                    @click="processSell()"
                    class="w-72 h-10 oeno_button_black mt-10 align-center"
                    :disabled="!validCases || processing"
                    >{{ processing ? "Processing" : "Continue" }}</button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { HTTP } from "@/axios.js";
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import WineDetail from "@/components/wine/WineDetailStockTable.vue";

export default {
  data() {
    return {
      showdata: false,
      stock: [],
      priceQuantities: [],
      maxcases: 1,
      amount: 1,

      validCases: true,
      processing: false,
      modalActive: false,

      wine: {},
      order: {},

      quantity: 1,
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
    WineDetail,
  },

  mounted() {
    this.$nextTick(() => {
      this.updateProfile();

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.get(
        "/api/get-stock/" +
          this.$route.params.id +
          "/" +
          this.$route.params.source,
        { headers: headers }
      )
        .then((response) => {
          if (response.data.statuscode == 200) {
            this.stock = response.data.data.stock;

            var priceStocks = [];

            this.maxcases = this.stock.stocks.length;

            this.stock.stocks.forEach((stock) => {
              const result = priceStocks.findIndex(
                (item) => item.sell_price === stock.sell_price
              );

              if (result > -1) {
                priceStocks[result].quantity = priceStocks[result].quantity + 1;
              } else {
                priceStocks.push({
                  sell_price: stock.sell_price,
                  name: stock.name,
                  quantity: 1,
                });
              }
            });

            priceStocks.sort(function (a, b) {
              return a.sell_price - b.sell_price;
            });

            this.priceQuantities = priceStocks;
            this.showdata = true;
          }
        })
        .catch((response) => {
          if (response.response.status == 401) {
            this.$store.dispatch("login", { token: "" });
            this.$router.push("/login");
            this.$store.dispatch("storePortfolioData", {});
          }
        });
    });
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
  },
  methods: {
    processSell() {
      /* Store the order data inot the store */

      var orderData = {
        stock: this.stock,
        quantity: this.quantity,
        subtotal: this.calcSubTotal() + this.calcCharge(),
        item_price: (this.calcSubTotal() + this.calcCharge()) / this.quantity,
        delivery: 0,
        currency: "GBP",
        duty: 0,
        vat: 0,
        target: "inbond",
        total: this.calcTotal(),
        deliveryAddress: {
          firstname: this.$store.state.user.firstname,
          surname: this.$store.state.user.surname,
          telephone: this.$store.state.user.telephone,
          email: this.$store.state.user.email,
          address_line1: this.$store.state.user.address_line1,
          address_line2: this.$store.state.user.address_line2,
          town: this.$store.state.user.town,
          county: this.$store.state.user.county,
          country: this.$store.state.user.country,
          postcode: this.$store.state.user.postcode,
        },
        bondDetails: {
          company_name: "",
          point_of_contact: "",
          point_of_contact_phone: "",
          point_of_contact_email: "",
          company_address: "",
          customer_code: "",
        },
      };

      this.$store.dispatch("storeOrderData", orderData).then(() => {
        this.$router.push({
          name: "page--buy--invest-target",
          params: {
            id: this.$route.params.id,
            source: this.$route.params.source,
            sell_size: this.$route.params.sell_size,
          },
        });
      });
    },
    validateCases(max) {
      if (parseFloat(this.quantity) < 1) {
        this.validCases = false;
      } else if (parseFloat(this.quantity) > max) {
        this.validCases = true;
        this.quantity = this.maxcases;
      } else {
        this.validCases = true;
      }
    },

    calcSubTotal() {
      var retValue = 0;
      var amountToSell = this.quantity;

      this.priceQuantities.forEach((priceQuantity) => {
        if (amountToSell > 0) {
          if (amountToSell > priceQuantity.quantity) {
            retValue =
              retValue + priceQuantity.quantity * priceQuantity.sell_price;
            amountToSell = amountToSell - priceQuantity.quantity;
          } else {
            retValue = retValue + amountToSell * priceQuantity.sell_price;
            amountToSell = 0;
          }
        }
      });

      return retValue;
    },

    calcCharge() {
      var retValue = 0;
      var subtotal = this.calcSubTotal();

      retValue = subtotal * parseFloat(process.env.VUE_APP_BUYER_CHARGE_PERC);

      return retValue;
    },
    calcTotal() {
      var retValue = 0;
      var subtotal = this.calcSubTotal();
      var charges = this.calcCharge();

      retValue = subtotal + charges;

      return retValue;
    },
  },
};
</script>
